import React, { useState, useEffect } from 'react';

function Home() {

  return (
    <>
      <h1>Event Manager</h1>
      <p>This app can be used to organize event information</p>
    </>
  );
}

export default Home;
