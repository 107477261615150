import React from 'react';

function NotFound() {
  return (
    <>
      <h1>404: Page Not Found</h1>
    </>
  )
}

export default NotFound;